/* eslint-disable no-param-reassign */
import React from 'react';
import { BaseGrid, CheckboxCellRenderer, GridFieldConfiguration } from '@samc/react-ui-grid';
import { usePicklistGridFields } from '@samc/picklist-core';
import { FieldConfigurationMember, PicklistSetting } from '@samc/picklist-api';
import { styled } from 'styletron-react';
import { ValuationWorkflowTemplateStage, ValuationWorkflowTemplateTask } from '../../../valuationApi';
import { CaseInsensitiveStringEquals } from '../../functions/CaseInsensitiveStringEquals';

const fieldConfigurationMembers: Record<string, FieldConfigurationMember> = {
    ResponsibleRoleIds: {
        viewFieldName: 'ResponsibleRoleIds',
        visibleExpression: 'true',
        inputType: 9,
        picklistField: {
            id: 'Workflow_Template_Role_Picklist',
            picklistId: 'ROEN_Role_Picklist',
            displaySetting: 1,
            setting: PicklistSetting.MultiSelect,
        },
        tooltip: 'Responsible Role Ids',
    },
};

const gridFields: GridFieldConfiguration[] = [
    {
        displayNameRule: 'Task Name',
        field: 'Name',
        type: 'string',
    },
    {
        displayNameRule: 'Role(s) Responsible for Completing Task',
        field: 'ResponsibleRoleIds',
        type: 'string',
    },
    {
        displayNameRule: 'Optional',
        field: 'IsOptional',
        type: 'string',
        cellRenderer: CheckboxCellRenderer,
    },
];

interface Props {
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows. The order of elements in this array will
     * determine the order of the Stage headers in the grid.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTasks which will comprise the contents of the WorkflowTemplatePanel.
     */
    workflowTemplateTasks: ValuationWorkflowTemplateTask[];
}

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    height: '75vh',
    minHeight: '250px',
}));

export const WorkflowTemplateGrid = (props: Props): React.ReactElement => {
    const { workflowTemplateStages, workflowTemplateTasks } = props;

    const { wrappedFields, frameworkComponents } = usePicklistGridFields({
        // could add gridDomain here to apply filters, but since this grid uses local data, that's probably not necessary
        fieldConfigurationMembers,
        gridFields,
    });

    /**
     * Reduce the full collection of ValuationWorkflowTemplateTasks to a Lookup by StageId.
     *
     * {
     *     id-of-workflow-template-stage-0: [workflow-template-tasks-for-workflow-template-stage-0],
     *     ...
     * }
     */
    const workflowTemplateStageToWorkflowTemplateTasksMap = React.useMemo(() => {
        return workflowTemplateTasks.reduce(
            (newMap, task) => {
                newMap[task.WorkflowTemplateStageId.toLocaleUpperCase()] = [
                    ...(newMap[task.WorkflowTemplateStageId.toLocaleUpperCase()] || []),
                    task,
                ];
                return newMap;
            },
            {} as { [stageId: string]: ValuationWorkflowTemplateTask[] },
        );
    }, [workflowTemplateTasks]);

    const flattenedData = React.useMemo(() => {
        return workflowTemplateStages.reduce((newArr, stage: ValuationWorkflowTemplateStage) => {
            const workflowTemplateTasksForStage =
                workflowTemplateStageToWorkflowTemplateTasksMap[stage.Id.toLocaleUpperCase()] || [];

            return [
                ...newArr,
                {
                    __FULL_WIDTH__: true,
                    __FULL_WIDTH_TEXT__: workflowTemplateStages
                        .find((templateStage) => CaseInsensitiveStringEquals(templateStage.Id, stage.Id))
                        ?.Name.toLocaleUpperCase(),
                },
                ...workflowTemplateTasksForStage,
            ];
        }, [] as unknown[]);
    }, [workflowTemplateStages, workflowTemplateStageToWorkflowTemplateTasksMap]);

    return (
        <StyledGridWrapper>
            <BaseGrid
                data={flattenedData}
                fields={wrappedFields}
                frameworkComponents={frameworkComponents}
                sizeColumnsToFit
                suppressPaginationPanel
                suppressRowSelector
                suppressAlternatingRowColors
            />
        </StyledGridWrapper>
    );
};

export default WorkflowTemplateGrid;
