/* eslint-disable import/no-relative-packages */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { ViewSetAction } from '@samc/screen-config-api';
import {
    ActionOverrideContextProvider,
    GridSelectionProvider,
    ServiceDrivenViewSet,
    ViewSetSubtabChangedEvent,
} from '@samc/screen-config-core';
import { ColumnConfigurations, EditingProvider, useMoveUpDown } from '@samc/react-ui-grid';
import {
    NavigateOptions,
    createPath,
    generatePath,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import {
    HeaderContextControlBarProps,
    HeaderContextProvider,
    RoutingWrapper,
    useHeaderContext,
    useRouterBlockerAndPromptForUnsavedChanges,
} from '@samc/react-ui-history';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { Entitlements } from '@samc/single-spa-authentication';
import { getCurrentUser } from '@samc/common';
import { styled } from 'styletron-react';
import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { overridenViewids } from './overrides';
import { ViewID } from '../../components/static/ValuationConstants';
import DeleteValuationGroup from '../../components/organisms/DeleteActionOverride/DeleteValuationGroup';
import DeleteReviewComment from '../../components/organisms/DeleteActionOverride/DeleteReviewComment';
import { ChangeWorkflow } from '../../components/organisms/ChangeWorkflow';
import { decodeBase64, decodeBase64ToObject } from '../../util';
import { renderDEATab } from '../../components/organisms/DEATab/DEATab';
import DEAWorkflowBoxRequestWrapper from '../../components/request-wrappers/DEATrackingHelperWrapper/DEATrackingHelperRequestWrapper';
import { renderEsInputTab } from '../../components/organisms/EsInputTab/EsInputTab';
import { CustomTabRenderer } from '../../components/organisms/CustomTabRenderer/CustomTabRenderer';
import { WorkflowBoxRequestWrapper } from '../../components/request-wrappers/WorkflowBoxRequestWrapper/WorkflowBoxRequestWrapper';
import { URL_PARAMNAME_DEFAULTS } from '../../components/organisms/FieldDifference/FieldDifference.Wrapper';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export interface ViewSetPageProps {
    /**
     * Useful in scenarios where the route does not contain the tabId param
     */
    tabIdOverride?: string;
}

interface ViewSetMainProps {
    triggeredAction: (ViewSetAction & { primaryKeyValue?: string }) | undefined;
    updateAction: React.Dispatch<ViewSetAction | undefined>;
    showConfigModal?: boolean;
    setShowConfigModal?: (showConfigModal: boolean) => void;
    onTabChanged: (newId: string) => void;
    onSubtabChanged: (ev: ViewSetSubtabChangedEvent) => void;
    tabId?: string;
    subtabId?: string;
}

interface ViewSetPageUrlParams extends Record<string, string | undefined> {
    id: string;
    tabId: string | undefined;
    subtabId: string | undefined;
}

export const VIEWSET_PAGE_PATH_FORMAT = '/screens/:id/:tabId?/:subtabId?';
const URL_PARAMNAME_FILTER = 'filter';

const ViewSetMain = (props: ViewSetMainProps): React.ReactElement => {
    const { triggeredAction, updateAction, tabId, onTabChanged, onSubtabChanged, subtabId } = props;
    const urlParams = useParams() as ViewSetPageUrlParams;
    const { viewId } = triggeredAction ?? { viewId: '' };

    const { DeaTabId, EsInputTabId } = useApplicationContext();

    const [searchParams, setSearchParams] = useSearchParams();

    const { reset: resetHeader } = useHeaderContext();
    const [selectedRows, onSelectionChanged] = React.useState<Record<string, unknown>[]>([]);
    const [viewsetKey, updateViewsetKey] = React.useState<number>(1);

    // run before other effects
    React.useLayoutEffect(() => {
        resetHeader(); // reset on load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        onSelectionChanged([]); // clear any row selections when viewsetid changes
    }, [urlParams.id]);

    useRouterBlockerAndPromptForUnsavedChanges();

    const primaryKey = React.useMemo(
        (): string | undefined => searchParams.get('primaryKeyValue') || undefined,
        [searchParams],
    );
    const encodedDefaultValuesJson = React.useMemo(() => searchParams.get(URL_PARAMNAME_DEFAULTS), [searchParams]);
    const encodedFilterExpression = React.useMemo(() => searchParams.get(URL_PARAMNAME_FILTER), [searchParams]);
    const portfolioId = React.useMemo(
        () => searchParams.get('portfolioId') ?? searchParams.get('PortfolioId'),
        [searchParams],
    );

    const defaultValues = React.useMemo(() => {
        if (!encodedDefaultValuesJson || !encodedDefaultValuesJson.length) {
            return undefined;
        }
        try {
            return decodeBase64ToObject<Record<string, unknown>>(encodedDefaultValuesJson);
        } catch {
            console.error(`Unable to decode or parse default data '${encodedDefaultValuesJson}'.`); // eslint-disable-line no-console
            return undefined;
        }
    }, [encodedDefaultValuesJson]);

    const urlFilters = React.useMemo((): string[] => {
        // return nothing if not set
        if (!encodedFilterExpression || !encodedFilterExpression.length) return [];

        const decodedExpression = decodeBase64(encodedFilterExpression);
        try {
            const filterArray = JSON.parse(decodedExpression);
            if (filterArray && Array.isArray(filterArray)) {
                return filterArray;
            }
        } catch (e) {
            // intentionally left empty
        }

        // fall back to prior behavior and return single expression in array
        return [decodedExpression];
    }, [encodedFilterExpression]);

    const setPrimaryKey = React.useCallback(
        (value?: string): void => {
            if (value) {
                setSearchParams((prevSearchParams) => ({ ...prevSearchParams, primaryKeyValue: value }));
            } else {
                searchParams.delete('primaryKeyValue');
                setSearchParams(searchParams);
            }
        },
        [searchParams, setSearchParams],
    );

    const renderTab = React.useCallback((params: CustomScreenParams) => {
        const deaTabResult = renderDEATab(params, DeaTabId);
        if (deaTabResult) return deaTabResult;

        const esInputResult = renderEsInputTab(params, EsInputTabId);
        if (esInputResult) return esInputResult;

        // returning undefined here will use the default implementation
        const { parentViewSetIds, tabId: _tabId } = params;

        if (
            parentViewSetIds.length >= 1 &&
            (_tabId === 'ValuationGroupAsset' ||
                _tabId === 'GroupDetails' ||
                _tabId === 'ValuationsByValuationGroup' ||
                _tabId === 'AddAssetToValuation' ||
                _tabId === 'ValuationSetupInfo')
        )
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <CustomTabRenderer {...params} />;
        return undefined;
    }, []);

    const refreshViewSet = React.useCallback(() => {
        onSelectionChanged([]);
        return updateViewsetKey(viewsetKey + 1);
    }, [viewsetKey]);

    const StyledFlexRow = styled('span', () => ({
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5em',
        justifyContent: 'space-between',
    }));

    const user = getCurrentUser();

    const canViewDebtEquity = React.useMemo(
        () => user?.hasEntitlement(Entitlements.DataAccess.ValuationViewDebtEquity),
        [user],
    );

    const workflowBoxRenderer = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_props: Omit<HeaderContextControlBarProps, 'precedingControls'>): React.ReactElement => {
            return (
                <StyledFlexRow>
                    <WorkflowBoxRequestWrapper valuationId={primaryKey as string} />
                    {canViewDebtEquity && (
                        <DEAWorkflowBoxRequestWrapper
                            valuationId={primaryKey as string}
                            refreshViewSet={refreshViewSet}
                        />
                    )}
                </StyledFlexRow>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [primaryKey, canViewDebtEquity],
    );

    return (
        <div
            key={viewsetKey}
            style={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', overflow: 'auto', width: '100%' }}
        >
            {urlParams.id && (
                // key here is set this way so that when the porfolio filter is changed, it will recreate/reload the data
                <ServiceDrivenViewSet
                    onSelectionChanges={onSelectionChanged}
                    key={`${urlParams.id}_${portfolioId}`}
                    viewSetId={urlParams.id}
                    filters={urlFilters}
                    primaryKeyValue={primaryKey}
                    setPrimaryKeyValue={setPrimaryKey}
                    defaultData={defaultValues}
                    tabRenderer={renderTab}
                    precedingControlRenderer={primaryKey ? workflowBoxRenderer : undefined}
                    initialSelectedTabId={tabId}
                    onTabChanged={onTabChanged}
                    parentScope={`Valuation_${portfolioId}`}
                    initialSelectedSubtabId={subtabId}
                    onSubtabChanged={onSubtabChanged}
                />
            )}
            {triggeredAction && (
                <>
                    {(viewId === ViewID.DeleteValuationGroup || viewId === ViewID.DeleteValuationGroup) && (
                        <DeleteValuationGroup
                            refreshViewSet={refreshViewSet}
                            updateAction={updateAction}
                            valuationGroupId={triggeredAction.primaryKeyValue ? triggeredAction.primaryKeyValue : ''}
                        />
                    )}

                    {(viewId === ViewID.DeleteReviewComment || viewId === ViewID.DeleteReviewComment) && (
                        <DeleteReviewComment
                            refreshViewSet={refreshViewSet}
                            updateAction={updateAction}
                            reviewCommentId={triggeredAction.primaryKeyValue ? triggeredAction.primaryKeyValue : ''}
                        />
                    )}
                    {(viewId === ViewID.ChangeWorkflow || viewId === ViewID.ChangeWorkflow) && (
                        <ChangeWorkflow
                            refreshViewSet={refreshViewSet}
                            updateAction={updateAction}
                            valuations={selectedRows as Record<string, unknown>[]}
                        />
                    )}
                </>
            )}
        </div>
    );
};

interface State {
    isDirty?: boolean;
    customColumns?: ColumnConfigurations[];
}

const ViewSetPageInner = (props: ViewSetPageProps): React.ReactElement => {
    const { tabIdOverride } = props;

    const [triggeredAction, updateAction] = React.useState<ViewSetAction>();
    const [showConfigModal, setShowConfigModal] = React.useState<boolean>(false);
    const { tabs } = useHeaderContext();

    const _navigate = useNavigate();
    const _urlParams = useParams() as ViewSetPageUrlParams;

    const urlParams = React.useMemo(
        (): ViewSetPageUrlParams => ({
            ..._urlParams,
            ...(tabIdOverride && {
                tabId: tabIdOverride,
            }),
        }),
        [_urlParams, tabIdOverride],
    );

    const originalLocation = useLocation();

    const navigate = React.useCallback(
        (paramUpdates: Pick<Partial<ViewSetPageUrlParams>, 'subtabId' | 'tabId'>, options?: NavigateOptions): void => {
            const { subtabId, tabId, ...rest } = urlParams;

            const pathParams = {
                ...rest,
                subtabId: subtabId ?? null,
                tabId: tabId ?? null,
                ...(paramUpdates.tabId && {
                    // if menu item changes, should clear the subtab as the form view will change
                    subtabId: undefined,
                }),
                ...paramUpdates,
            };

            const newPath = generatePath(
                VIEWSET_PAGE_PATH_FORMAT, // must uri-encode all
                Object.entries(pathParams).reduce(
                    (all, [attr, val]) => (val ? { ...all, [attr]: encodeURIComponent(val) } : all),
                    pathParams,
                ),
            );

            _navigate(
                createPath({
                    pathname: newPath,
                    search: originalLocation.search,
                    hash: originalLocation.hash,
                }),
                options,
            );
        },
        [_navigate, originalLocation.hash, originalLocation.search, urlParams],
    );

    const onTabIdChanged = React.useCallback(
        (newId: string, userRequested?: boolean) => {
            if (newId === urlParams.tabId) return; // do nothing if id doesn't change

            navigate(
                {
                    tabId: newId,
                },
                { replace: !userRequested },
            );
        },
        [navigate, urlParams.tabId],
    );

    const onSubtabIdChanged = React.useCallback(
        (ev: ViewSetSubtabChangedEvent) => {
            const { newId, userRequested } = ev;

            if (newId === urlParams.subtabId) return; // do nothing if id doesn't change

            navigate(
                {
                    subtabId: newId,
                },
                { replace: !userRequested },
            );
        },
        [navigate, urlParams.subtabId],
    );

    const onTabIndexChanged = React.useCallback(
        (newIndex: number, userRequested: boolean) => {
            if (!tabs) return;

            const tab = tabs[newIndex];
            if (!tab) return;

            onTabIdChanged(tab.id, userRequested);
        },
        [onTabIdChanged, tabs],
    );

    const gridSelector = 'VALU_ReviewComment';
    const columnName = 'CommentSequence';
    const [moveSelectedRow] = useMoveUpDown(columnName);

    const customContextMenuList = React.useMemo(
        () =>
            ({
                customColumns: [
                    {
                        visibleRule: 'false',
                        gridSelector: gridSelector as string,
                        getContextMenuItem: (params: GetContextMenuItemsParams): MenuItemDef[] => [
                            {
                                name: 'Move Up',
                                action: (): void => {
                                    moveSelectedRow(params, -1);
                                },
                            },
                            {
                                name: 'Move Down',
                                action: (): void => {
                                    moveSelectedRow(params, 1);
                                },
                            },
                        ],
                    },
                ],
            }) as State,
        [moveSelectedRow],
    );

    return (
        <EditingProvider value={customContextMenuList}>
            <ActionOverrideContextProvider
                value={overridenViewids.reduce(
                    (o, v) => ({ ...o, [v]: (item: ViewSetAction): void => updateAction(item) }),
                    {},
                )}
            >
                <GridSelectionProvider>
                    <RoutingWrapper onTabChanged={onTabIndexChanged}>
                        <ViewSetMain
                            triggeredAction={triggeredAction}
                            updateAction={updateAction}
                            showConfigModal={showConfigModal}
                            setShowConfigModal={setShowConfigModal}
                            tabId={urlParams.tabId}
                            onTabChanged={onTabIdChanged}
                            subtabId={urlParams.subtabId}
                            onSubtabChanged={onSubtabIdChanged}
                        />
                    </RoutingWrapper>
                </GridSelectionProvider>
            </ActionOverrideContextProvider>
        </EditingProvider>
    );
};

export const ViewSetPage = (props: ViewSetPageProps): JSX.Element => (
    <HeaderContextProvider>
        <ViewSetPageInner {...props} />
    </HeaderContextProvider>
);

export default ViewSetPage;
