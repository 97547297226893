/* istanbul ignore file */
import React from 'react';
import { styled } from 'styletron-react';
import { BaseGrid, GridFieldConfiguration } from '@samc/react-ui-grid';
import { useDomain } from '@samc/screen-config-api';
import { FieldConfigurationMember, InputType, PicklistSetting } from '@samc/picklist-api';
import { usePicklistGridFields as _usePicklistGridFields } from '@samc/picklist-core';
import { ColDef, GridOptions, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { ValuationContact } from '../../../valuationApi/models/ValuationContact';
import { useApiContext } from '../../../hooks/useApiContext';
import { VALUATION_CONTACTS_DOMAIN_ID } from '../../../valuationApi';

const fieldConfigurationMembers: Record<string, FieldConfigurationMember> = {
    RoleId: {
        viewFieldName: 'RoleId',
        visibleExpression: 'true',
        inputType: InputType.Picklist,
        picklistField: {
            id: 'Workflow_AdHoc_Task_Role_Picklist',
            picklistId: 'ROEN_Role_Picklist',
            displaySetting: 1,
            setting: PicklistSetting.Single,
        },
        tooltip: 'Role',
    },
    UserId: {
        viewFieldName: 'UserId',
        visibleExpression: 'true',
        inputType: InputType.Picklist,
        picklistField: {
            id: 'Workflow_AdHoc_Task_User_Picklist',
            picklistId: 'User',
            displaySetting: 1,
            setting: PicklistSetting.Single,
        },
        tooltip: 'User',
    },
};

const gridFields: GridFieldConfiguration[] = [
    {
        displayNameRule: 'Role',
        field: 'RoleId',
        type: 'string',
    },
    {
        displayNameRule: 'User',
        field: 'UserId',
        type: 'string',
    },
];

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    height: '25vh',
    minHeight: '250px',
}));

interface Props {
    /**
     * The id of the Valuation for which to fetch Valuation Contacts.
     */
    valuationId: string;
    /**
     * Indicates if the grid should render, or if it should display its errored state.
     */
    isError?: boolean;
    /**
     * Indicates if the grid should render, or if it should display its loading state.
     */
    isLoading?: boolean;
    /**
     * Callback to be used to determine which rows are selectable. By default rows are selectable, so return `false` to make a row un-selectable.
     */
    isRowSelectable?: ColDef<ValuationContact>['checkboxSelection'];
    /**
     * Callback for each row in the grid called upon initial load to set the selected state of the row.
     */
    isRowSelected?: (row: IRowNode<ValuationContact>) => boolean;
    /**
     * Row is selected or deselected. The event contains the node in question, so call the node's `isSelected()` method to see if it was just selected or deselected.
     */
    onRowSelected?: GridOptions<ValuationContact>['onRowSelected'];
    /**
     * Row selection is changed. Use the grid API `getSelectedNodes()` or `getSelectedRows()` to get the new list of selected nodes / row data.
     */
    onSelectionChanged?: GridOptions<ValuationContact>['onSelectionChanged'];
    /**
     * Set to `true` to display a disabled checkbox when row is not selectable and checkboxes are enabled.
     */
    showDisabledCheckboxes?: ColDef<ValuationContact>['showDisabledCheckboxes'];
    /**
     * DI override of the hook to builds out the GridFieldConfigurations for a domain
     * that contains picklist fields.
     */
    usePicklistGridFields?: typeof _usePicklistGridFields;
}

export const WorkflowContactGrid = ({
    isError,
    isLoading,
    isRowSelectable,
    isRowSelected,
    onRowSelected,
    onSelectionChanged,
    showDisabledCheckboxes,
    usePicklistGridFields = _usePicklistGridFields,
    valuationId,
}: Props): React.ReactElement => {
    const { useValuationContacts } = useApiContext();

    const { data: domain, isLoading: isDomainLoading } = useDomain(VALUATION_CONTACTS_DOMAIN_ID);

    const { wrappedFields, frameworkComponents } = usePicklistGridFields({
        fieldConfigurationMembers,
        gridFields,
        gridDomain: domain,
    });

    const valuationContactsQuery = useValuationContacts(valuationId);

    const selectInitialRows = (event: GridReadyEvent<ValuationContact>): void => {
        event.api.forEachNode((node) => node.setSelected(isRowSelected?.(node) ?? false));
    };

    if (!!isLoading || valuationContactsQuery.isLoading || isDomainLoading) {
        return <Spinner data-testid="spinner-WorkflowContactGrid" />;
    }

    if (!!isError || valuationContactsQuery.isError) {
        return <MessageBar text="Error fetching valuation contacts." messageBarType={MessageBarType.error} />;
    }

    return (
        <StyledGridWrapper>
            <BaseGrid<ValuationContact>
                data={valuationContactsQuery.data?.Data as unknown as ValuationContact[]}
                fields={wrappedFields}
                frameworkComponents={frameworkComponents}
                onBodyGridReady={selectInitialRows}
                onRowSelected={onRowSelected}
                onSelectionChanged={onSelectionChanged}
                sizeColumnsToFit
                suppressPaginationPanel
                suppressAlternatingRowColors
                showDisabledCheckboxes={showDisabledCheckboxes}
                checkboxSelection={isRowSelectable}
            />
        </StyledGridWrapper>
    );
};

export default WorkflowContactGrid;
