/* eslint-disable no-param-reassign */
import React from 'react';
import { BaseGrid, DetailProps, GridFieldConfiguration } from '@samc/react-ui-grid';
import { styled } from 'styletron-react';
import { FieldConfigurationMember, InputType, PicklistSetting } from '@samc/picklist-api';
import { PicklistCellRendererProps, usePicklistGridFields } from '@samc/picklist-core';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import {
    fetchApi as _fetchApi,
    useValuationContacts as _useValuationContacts,
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses,
    ValuationApiOverrides,
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
    ValuationWorkflowTemplateStage,
    ValuationWorkflowTemplateTaskStatus,
} from '../../../valuationApi';
import { useWorkflowTasksWithWorkflowTaskStatus } from '../../hooks/useWorkflowTasksWithWorkflowTaskStatus/useWorkflowTasksWithWorkflowTaskStatus';
import { useWorkflowTasksWithWorkflowTemplateTaskStatuses } from '../../hooks/useWorkflowTasksWithWorkflowTemplateTaskStatuses/useWorkflowTasksWithWorkflowTemplateTaskStatuses';
import { WorkflowTaskValidationPopup } from '../WorkflowTaskValidationPopup/WorkflowTaskValidationPopup';
import { WorkflowTaskDueDateRenderer } from '../../cell-renderers/WorkflowTaskDueDateRenderer/WorkflowTaskDueDateRenderer';
import { CaseInsensitiveStringEquals } from '../../functions/CaseInsensitiveStringEquals';
import { useWorkflowTaskActionsRenderer } from '../../hooks/useWorkflowTaskActionsRenderer/useWorkflowTaskActionsRenderer';
import { useWorkflowParticipantRenderer } from '../../hooks/useWorkflowParticipantRenderer/useWorkflowParticipantRenderer';
import { WorkflowTaskNameRenderer } from '../../cell-renderers/WorkflowTaskNameRenderer/WorkflowTaskNameRenderer';
import { WorkflowActionsLogGrid } from '../../atoms/WorkflowActionsLogGrid/WorkflowActionsLogGrid';

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    height: '50vh',
    minHeight: '250px',
}));

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuationContacts'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskStatuses'
    > {
    /**
     * The Id of the Valuation to fetch.
     */
    valuationId: string;
    /**
     * The collection of ValuationWorkflowTasks to display in the Grid.
     */
    workflowTasks: ValuationWorkflowTask[];
    /**
     * A collection of ValuationWorkflowTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTaskStatus of a given ValuationWorkflowTask.
     */
    workflowTaskStatuses: ValuationWorkflowTaskStatus[];
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows. The order of elements in this array will
     * determine the order of the Stage headers in the grid.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTemplateTaskStatuses of a given ValuationWorkflowTask.
     */
    workflowTemplateTaskStatuses: ValuationWorkflowTemplateTaskStatus[];
}

export const WorkflowTaskGrid = ({
    fetchApi = _fetchApi,
    valuationId,
    useValuationContacts = _useValuationContacts,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
    workflowTasks,
    workflowTaskStatuses,
    workflowTemplateStages,
    workflowTemplateTaskStatuses,
}: Props): React.ReactElement => {
    const taskActionsCellRenderer = useWorkflowTaskActionsRenderer({ fetchApi, useValuationWorkflowTaskStatuses });

    const valuationContactsResponse = useValuationContacts(valuationId);

    /**
     * set comma separated userIds from valuation contact of valuationId
     */
    const valuationContactUserIds = React.useMemo(() => {
        return valuationContactsResponse?.data?.Data.map((vc) => {
            return vc.UserId;
        }).join(',');
    }, [valuationContactsResponse]);

    const fieldConfigurationMembers = React.useMemo((): Record<string, FieldConfigurationMember> => {
        return {
            ResponsibleParticipant: {
                viewFieldName: 'ResponsibleParticipant',
                visibleExpression: 'true',
                inputType: InputType.Picklist,
                picklistField: {
                    id: 'Workflow_Task_ResponsibleParticipant_Picklist',
                    picklistId: 'User',
                    displaySetting: 1,
                    setting: PicklistSetting.MultiSelect,
                    filterExpression: `DoesContain([UserId], '${valuationContactUserIds}')`,
                },
                tooltip: 'Responsible Participants',
            },
            WorkflowTaskStatusId: {
                viewFieldName: 'WorkflowTaskStatusId',
                visibleExpression: 'true',
                inputType: InputType.Picklist,
                picklistField: {
                    id: 'VALU_ValuationWorkflowTaskStatus_Picklist',
                    picklistId: 'VALU_ValuationWorkflowTaskStatus_Picklist',
                    displaySetting: 1,
                    setting: PicklistSetting.Single,
                },
                tooltip: 'Status',
            },
        };
    }, [valuationContactUserIds]);

    const gridFields: GridFieldConfiguration[] = [
        {
            displayNameRule: 'Task',
            field: 'Name',
            type: 'string',
            cellRenderer: WorkflowTaskNameRenderer,
        },
        {
            displayNameRule: 'Due Date',
            field: 'DueDate',
            type: 'date',
            cellRenderer: WorkflowTaskDueDateRenderer,
            width: 100,
        },
        {
            displayNameRule: 'Status',
            field: 'WorkflowTaskStatusId',
            type: 'string',
            width: 120,
        },
        {
            displayNameRule: 'Actions',
            field: 'Actions',
            type: 'string',
            cellRenderer: taskActionsCellRenderer,
            filter: false,
        },
        {
            displayNameRule: 'Responsible Participant',
            field: 'ResponsibleParticipant',
            type: 'string',
        },
        {
            displayNameRule: 'Actions Log',
            field: 'ActionsLog',
            type: 'string',
        },
    ];

    const responsibleParticipantCellRenderer = useWorkflowParticipantRenderer({
        fetchApi,
        useValuationContacts,
        useValuationWorkflowTask,
        useValuationWorkflowTaskOwners,
    });

    const customRenderers: Record<
        string,
        (props: PicklistCellRendererProps, displayValue?: string) => React.ReactElement
    > = {
        ResponsibleParticipant: responsibleParticipantCellRenderer,
    };

    const { wrappedFields, frameworkComponents } = usePicklistGridFields({
        // could add gridDomain here to apply filters, but since this grid uses local data, that's probably not necessary
        fieldConfigurationMembers,
        gridFields,
        customRenderers,
    });

    const workflowTasksWithWorkflowTemplateTaskStatuses = useWorkflowTasksWithWorkflowTemplateTaskStatuses(
        workflowTasks,
        workflowTemplateTaskStatuses,
    );

    const workflowTasksWithWorkflowTaskStatus = useWorkflowTasksWithWorkflowTaskStatus(
        workflowTasksWithWorkflowTemplateTaskStatuses,
        workflowTaskStatuses,
    );

    const adHocWorkflowTasks = React.useMemo(() => {
        return workflowTasksWithWorkflowTaskStatus.filter((task) => task.AdHocTaskFlag);
    }, [workflowTasksWithWorkflowTaskStatus]);

    /**
     * Reduce the full collection of ValuationWorkflowTasks to a Lookup by StageId.
     *
     * {
     *     id-of-workflow-template-stage-0: [workflow-tasks-for-workflow-template-stage-0],
     *     ...
     * }
     */
    const workflowTemplateStageToWorkflowTasksMap = React.useMemo(() => {
        return workflowTasksWithWorkflowTaskStatus
            .filter((task) => !task.AdHocTaskFlag)
            .reduce(
                (newMap, task) => {
                    if (!task.TaskStageId) return newMap;

                    newMap[task.TaskStageId.toLocaleUpperCase()] = [
                        ...(newMap[task.TaskStageId.toLocaleUpperCase()] || []),
                        task,
                    ];
                    return newMap;
                },
                {} as { [stageId: string]: ValuationWorkflowTask[] },
            );
    }, [workflowTasksWithWorkflowTaskStatus]);

    const flattenedData = React.useMemo(() => {
        return workflowTemplateStages.reduce((newArr, stage: ValuationWorkflowTemplateStage) => {
            const workflowTasksForStage = workflowTemplateStageToWorkflowTasksMap[stage.Id.toLocaleUpperCase()] || [];

            workflowTasksForStage.sort((a, b) => a.TaskGroup - b.TaskGroup);

            return [
                ...newArr,
                {
                    __FULL_WIDTH__: true,
                    __FULL_WIDTH_TEXT__: workflowTemplateStages
                        .find((templateStage) => CaseInsensitiveStringEquals(templateStage.Id, stage.Id))
                        ?.Name.toLocaleUpperCase(),
                },
                ...workflowTasksForStage,
            ];
        }, [] as unknown[]);
    }, [workflowTemplateStages, workflowTemplateStageToWorkflowTasksMap]);

    const dataIncludingAdHocTasks = React.useMemo(() => {
        if (adHocWorkflowTasks.length > 0) {
            return [
                ...flattenedData,
                {
                    __FULL_WIDTH__: true,
                    __FULL_WIDTH_TEXT__: 'AD HOC',
                },
                ...adHocWorkflowTasks,
            ];
        }

        return flattenedData;
    }, [adHocWorkflowTasks, flattenedData]);

    const detailGrid = ({ api, node }: DetailProps): React.ReactElement => {
        return <WorkflowActionsLogGrid api={api} node={node} />;
    };

    if (valuationContactsResponse.isLoading) {
        return <Spinner data-testid="spinner-WorkflowContactGrid" />;
    }

    if (valuationContactsResponse.isError) {
        return <MessageBar text="Error fetching valuation contacts." messageBarType={MessageBarType.error} />;
    }
    return (
        <>
            <StyledGridWrapper>
                <BaseGrid
                    defaultDetailRowHeight={300}
                    data={dataIncludingAdHocTasks}
                    detailComponent={detailGrid}
                    fields={wrappedFields}
                    frameworkComponents={frameworkComponents}
                    sizeColumnsToFit
                    suppressPaginationPanel
                    suppressRowSelector
                    suppressAlternatingRowColors
                />
            </StyledGridWrapper>
            <WorkflowTaskValidationPopup />
        </>
    );
};

export default WorkflowTaskGrid;
