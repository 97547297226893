import React from 'react';
import { DetailGrid, GridFieldConfiguration, GridStateChangeParams } from '@samc/react-ui-grid';
import { GridApi, RowNode } from 'ag-grid-community';
import { useQueryClient } from 'react-query';
import { QueryExecuteResponse, useDomain } from '@samc/screen-config-api';
import { FieldConfigurationMember, InputType, PicklistSetting } from '@samc/picklist-api';
import { usePicklistGridFields } from '@samc/picklist-core';
import { ProgressIndicator } from '@fluentui/react';
import { useApiContext } from '../../../hooks/useApiContext';
import { executeLiftSiftQuery } from '../../../valuationApi/api/Requests';
import {
    BuildValuationWorkflowLogsRequestBody,
    VALUATION_WORKFLOW_LOGS_DOMAIN,
    VALUATION_WORKFLOW_LOGS_KEY,
} from '../../../valuationApi';

const fieldConfigurationMembers: Record<string, FieldConfigurationMember> = {
    UserId: {
        viewFieldName: 'User',
        visibleExpression: 'true',
        inputType: InputType.Picklist,
        picklistField: {
            id: 'Workflow_Logs_User_Picklist',
            picklistId: 'User',
            displaySetting: 1,
            setting: PicklistSetting.Single,
        },
    },
};

const gridFields: GridFieldConfiguration[] = [
    {
        displayNameRule: 'Change',
        field: 'Description',
        type: 'string',
    },
    {
        displayNameRule: 'Date',
        field: 'Timestamp',
        type: 'datetime',
    },
    {
        displayNameRule: 'User',
        field: 'UserId',
        type: 'string',
    },
    {
        displayNameRule: 'AutoComplete Flag',
        field: 'IsAutoComplete',
        type: 'boolean',
    },
];

interface Props {
    api: GridApi;
    node: RowNode;
}

export const WorkflowActionsLogGrid = ({ api, node }: Props): React.ReactElement => {
    const valuationApi = useApiContext();
    const queryClient = useQueryClient();

    const { data: domain, isLoading: isDomainLoading } = useDomain(VALUATION_WORKFLOW_LOGS_DOMAIN);

    const { wrappedFields, frameworkComponents } = usePicklistGridFields({
        fieldConfigurationMembers,
        gridFields,
        gridDomain: domain,
    });

    const onGridStateChange = async (params: GridStateChangeParams): Promise<void> => {
        const result = await queryClient.fetchQuery<QueryExecuteResponse>(
            [VALUATION_WORKFLOW_LOGS_KEY, node.data.Id],
            () =>
                executeLiftSiftQuery(
                    valuationApi.LiftSiftApi,
                    BuildValuationWorkflowLogsRequestBody(node.data.Id),
                    valuationApi.requestInit,
                    valuationApi.ShowMessage,
                ),
            { retry: 2 },
        );

        params.success({
            rowData: result.Data,
            rowCount: result.TotalRecords,
        });
    };

    if (isDomainLoading) return <ProgressIndicator label="Loading Domain..." />;

    return (
        <DetailGrid
            node={node}
            api={api}
            fields={wrappedFields}
            frameworkComponents={frameworkComponents}
            onGridStateChange={onGridStateChange}
        />
    );
};

export default WorkflowActionsLogGrid;
